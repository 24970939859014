import React, { useState, useContext, useEffect } from "react";

import { DeviceContext } from "../../contexts/DeviceContext";
import { TeamContext } from "../../contexts/TeamContext";
import CircularProgress from "../circular-progress/CircularProgress";

import Toast, { showToast } from "../toast/Toast";

import { Formik, Form, ErrorMessage } from "formik";
import logo from "../../assets/logo.png";
import styles from "./Header.module.css";

import cc from "classcat";
import * as Yup from "yup";

import {
  loginDevice,
  setCommandAsExecuted,
  postOnOffStateToCloud,
  upsertDeviceType,
  upsertDataType,
  upsertCommandTypes,
  upsertDevice,
  getTeamInfo
} from "../../lib/api";

import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent
} from "@reach/alert-dialog";

import config from "../../config/index";

import singletons from "../../lib/singletons";

const validationSchemaDeviceConnection = Yup.object().shape({
  teamUuid: Yup.string()
    .matches(
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
      "Please enter a valid UUID."
    )
    .required("Please fill out this field."),
  key: Yup.string().required("Please fill out this field."),
  serial: Yup.string().required("Please fill out this field."),
  secret: Yup.string().required("Please fill out this field.")
});

const validationSchemaDeviceSecret = Yup.object().shape({
  secret: Yup.string()
    .min(6, "Must have at least 6 characters")
    .max(255, "Must be 255 or less characters")
    .required("Please fill out this field."),
  secret2: Yup.string()
    .required("Please fill out this field.")
    .label("Confirm secret")
    .test("passwords-match", "Secrets do not match", function(value) {
      return this.parent.secret === value;
    })
});

const Header = props => {
  // connect dialog
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);

  // setup dialog (Team API Key)
  const [
    isSetupDialogTeamApiKeyOpen,
    setIsSetupDialogTeamApiKeyOpen
  ] = useState(false);

  // setup dialog (Device Type)
  const [
    isSetupDialogDeviceTypeOpen,
    setIsSetupDialogDeviceTypeOpen
  ] = useState(false);

  // setup dialog (Data Type)
  const [isSetupDialogDataTypeOpen, setIsSetupDialogDataTypeOpen] = useState(
    false
  );

  // setup dialog (Command Types)
  const [
    isSetupDialogCommandTypesOpen,
    setIsSetupDialogCommandTypesOpen
  ] = useState(false);

  // setup dialog (Device)
  const [isSetupDialogDeviceOpen, setIsSetupDialogDeviceOpen] = useState(false);

  const { setIsOn } = useContext(DeviceContext);

  return (
    <>
      {isConnectDialogOpen ? (
        <ConnectDialog
          setIsConnectDialogOpen={setIsConnectDialogOpen}
          setIsSetupDialogTeamApiKeyOpen={setIsSetupDialogTeamApiKeyOpen}
          setIsOn={setIsOn}
        />
      ) : null}
      {isSetupDialogTeamApiKeyOpen ? (
        <SetupDialogTeamApiKey
          setIsConnectDialogOpen={setIsConnectDialogOpen}
          setIsSetupDialogTeamApiKeyOpen={setIsSetupDialogTeamApiKeyOpen}
          setIsSetupDialogDeviceTypeOpen={setIsSetupDialogDeviceTypeOpen}
        />
      ) : null}
      {isSetupDialogDeviceTypeOpen ? (
        <SetupDialogDeviceType
          setIsSetupDialogTeamApiKeyOpen={setIsSetupDialogTeamApiKeyOpen}
          setIsSetupDialogDeviceTypeOpen={setIsSetupDialogDeviceTypeOpen}
          setIsSetupDialogDataTypeOpen={setIsSetupDialogDataTypeOpen}
        />
      ) : null}
      {isSetupDialogDataTypeOpen ? (
        <SetupDialogDataType
          setIsSetupDialogDataTypeOpen={setIsSetupDialogDataTypeOpen}
          setIsSetupDialogCommandTypesOpen={setIsSetupDialogCommandTypesOpen}
        />
      ) : null}
      {isSetupDialogCommandTypesOpen ? (
        <SetupDialogCommandTypes
          setIsSetupDialogCommandTypesOpen={setIsSetupDialogCommandTypesOpen}
          setIsSetupDialogDeviceOpen={setIsSetupDialogDeviceOpen}
        />
      ) : null}
      {isSetupDialogDeviceOpen ? (
        <SetupDialogDevice
          setIsSetupDialogCommandTypesOpen={setIsSetupDialogCommandTypesOpen}
          setIsSetupDialogDeviceOpen={setIsSetupDialogDeviceOpen}
          setIsConnectDialogOpen={setIsConnectDialogOpen}
        />
      ) : null}
      <header className={styles.app_header}>
        <div style={{ flexGrow: 1 }}>
          <img
            src={logo}
            style={{ height: 70, display: "inline-block" }}
            alt="logo"
          />
        </div>
        <div style={{ flexGrow: 10 }}>
          <div className={styles.title}>Iotery Smart Light Emulator</div>
        </div>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <button
            className={styles.button}
            style={{ height: 45, fontSize: 15 }}
            onClick={() => {
              setIsConnectDialogOpen(true);
            }}
          >
            Connect
          </button>
        </div>
      </header>
    </>
  );
};

function ConnectDialog(props) {
  const {
    setIsConnectDialogOpen,
    setIsSetupDialogTeamApiKeyOpen,
    setIsOn
  } = props;

  const dialogCancelRef = React.createRef();

  const {
    setIsConnected,
    setToken,
    setDeviceInfo,
    teamUuid,
    deviceInfo,
    secret
  } = useContext(DeviceContext);

  const { setTeamUuid, setTeamName } = useContext(TeamContext);

  return (
    <AlertDialogOverlay
      leastDestructiveRef={dialogCancelRef}
      onDismiss={() => {
        setIsConnectDialogOpen(false);
      }}
    >
      <AlertDialogContent className={styles.dialog_box}>
        <Formik
          initialValues={{
            teamUuid: teamUuid,
            serial: deviceInfo.serial,
            key: deviceInfo.key,
            secret: secret
          }}
          validationSchema={validationSchemaDeviceConnection}
          onSubmit={async (values, { setSubmitting, isSubmitting }) => {
            setSubmitting(true);
            let res;
            let teamInfo;
            try {
              res = await loginDevice(values);
              teamInfo = await getTeamInfo({ token: res.token });
              showToast(<Toast type="success">Connected!</Toast>, {
                duration: 4000
              });
            } catch (err) {
              showToast(
                <Toast type="error">
                  Unable to connect. Please check your credentials.
                </Toast>,
                {
                  duration: 4000
                }
              );
              setIsConnected(false);
              setSubmitting(false);
              setIsConnectDialogOpen(false);
              return;
            }

            // Set team context
            setTeamUuid(values.teamUuid);
            setTeamName(teamInfo.name);

            // Setup MQTT
            singletons
              .getMqttInstance()
              .addCredentials(res.device.uuid, res.token, values.teamUuid);
            singletons.getMqttInstance().init();
            singletons.getMqttInstance().addOnMessageListener(
              handleMessage({
                setIsOn,
                deviceInfo: res.device,
                token: res.token
              })
            );
            singletons
              .getMqttInstance()
              .subscribe(`devices/${res.device.uuid}/commands`);

            // Update Device Context
            setIsConnected(true);
            setToken(res.token);
            setDeviceInfo(res.device);

            // Close dialog
            setSubmitting(false);
            setIsConnectDialogOpen(false);
          }}
          render={({ values, handleBlur, handleChange, isSubmitting }) => (
            <Form>
              <AlertDialogLabel>
                <h2>Connect Smart Light to Iotery</h2>
              </AlertDialogLabel>
              <AlertDialogDescription>
                <div style={{ textAlign: "justify" }}>
                  Enter your team ID and device credentials in order to connect
                  to Iotery.
                </div>
                <div className={styles.input_row}>
                  <label htmlFor="teamUuid">Team ID *</label>
                  <input
                    required
                    type="text"
                    name="teamUuid"
                    autoComplete="username"
                    placeholder="00000000-0000-0000-0000-000000000000"
                    value={values.teamUuid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="teamUuid"
                    component="div"
                    className={styles.input_error_text_container}
                  />
                </div>
                <div className={styles.input_row}>
                  <label htmlFor="serial">Device Serial *</label>
                  <input
                    required
                    type="text"
                    autoComplete="on"
                    name="serial"
                    placeholder="Enter your device serial"
                    value={values.serial}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="serial"
                    component="div"
                    className={styles.input_error_text_container}
                  />
                </div>
                <div className={styles.input_row}>
                  <label htmlFor="serial">Device Key *</label>
                  <input
                    required
                    type="text"
                    autoComplete="on"
                    name="key"
                    placeholder="Enter your device key"
                    value={values.key}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="key"
                    component="div"
                    className={styles.input_error_text_container}
                  />
                </div>
                <div className={styles.input_row}>
                  <label htmlFor="serial">Device Secret *</label>
                  <input
                    required
                    type="password"
                    autoComplete="off"
                    name="secret"
                    placeholder="Enter your device secret"
                    value={values.secret}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="secret"
                    component="div"
                    className={styles.input_error_text_container}
                  />
                </div>
              </AlertDialogDescription>
              <div className={styles.button_row}>
                <div style={{ flexGrow: 10 }}>
                  {isSubmitting ? (
                    <CircularProgress style={{ height: 20, width: 20 }} />
                  ) : (
                    <button
                      ref={dialogCancelRef}
                      className={styles.hyperlink}
                      style={{ textAlign: "left", backgroundColor: "white" }}
                      type="button"
                      onClick={() => {
                        setIsConnectDialogOpen(false);
                        setIsSetupDialogTeamApiKeyOpen(true);
                      }}
                    >
                      Still need to configure Iotery?
                    </button>
                  )}
                </div>
                <button
                  ref={dialogCancelRef}
                  type="button"
                  disabled={isSubmitting}
                  className={cc([styles.button, styles.button_cancel])}
                  onClick={() => {
                    setIsConnectDialogOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={styles.button}
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        />
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
}

function handleMessage({ setIsOn, deviceInfo, token }) {
  return async (topic, payload) => {
    const obj = JSON.parse(payload.toString());
    const topicParts = topic.split("/");

    if (topicParts[topicParts.length - 1] === "commands") {
      obj.map(async o => {
        //set command executed
        setCommandAsExecuted({ commandInstanceUuid: o.uuid, token });

        //update On/Off State
        if (o.commandTypeEnum === config.ioteryTypes.commands.turnOn) {
          setIsOn(true);
          postOnOffStateToCloud({
            deviceUuid: deviceInfo.uuid,
            deviceTypeUuid: deviceInfo.deviceTypeUuid,
            onOffState: 1,
            token
          });
        } else if (o.commandTypeEnum === config.ioteryTypes.commands.turnOff) {
          setIsOn(false);
          postOnOffStateToCloud({
            deviceUuid: deviceInfo.uuid,
            deviceTypeUuid: deviceInfo.deviceTypeUuid,
            onOffState: 0,
            token
          });
        }
      });
    }
  };
}

function SetupDialogTeamApiKey(props) {
  const {
    setIsConnectDialogOpen,
    setIsSetupDialogTeamApiKeyOpen,
    setIsSetupDialogDeviceTypeOpen
  } = props;

  const { setTeamApiKey } = useContext(TeamContext);

  const dialogCancelRef = React.createRef();

  return (
    <AlertDialogOverlay
      leastDestructiveRef={dialogCancelRef}
      onDismiss={() => {
        setIsSetupDialogTeamApiKeyOpen(false);
      }}
    >
      <AlertDialogContent className={styles.dialog_box}>
        <Formik
          initialValues={{
            teamApiKey: ""
          }}
          onSubmit={async (values, { setSubmitting, isSubmitting }) => {
            setSubmitting(true);
            setIsSetupDialogTeamApiKeyOpen(false);
            await setTeamApiKey(values.teamApiKey);
            setIsSetupDialogDeviceTypeOpen(true);
            setSubmitting(false);
          }}
          render={({ values, handleBlur, handleChange, isSubmitting }) => (
            <Form>
              <AlertDialogLabel>
                <h2>Iotery Configuration</h2>
              </AlertDialogLabel>
              <AlertDialogDescription>
                <div style={{ textAlign: "justify" }}>
                  Enter your team API key to get started.
                </div>
                <div className={styles.input_row}>
                  <label htmlFor="teamUuid">Team API Key *</label>
                  <input
                    required
                    type="text"
                    name="teamApiKey"
                    autoComplete="username"
                    placeholder="Team API Key"
                    value={values.teamApiKey}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </AlertDialogDescription>
              <div className={styles.button_row}>
                <div style={{ flexGrow: 10, fontSize: 12 }}>
                  Your API key can be found on the &nbsp;
                  <a
                    href="https://iotery.io/system"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Iotery System Page
                  </a>
                  .
                </div>
                <button
                  ref={dialogCancelRef}
                  type="button"
                  disabled={isSubmitting}
                  className={cc([styles.button, styles.button_cancel])}
                  onClick={() => {
                    setIsSetupDialogTeamApiKeyOpen(false);
                    setIsConnectDialogOpen(true);
                  }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className={styles.button}
                  disabled={isSubmitting}
                >
                  Next
                </button>
              </div>
            </Form>
          )}
        />
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
}

function SetupDialogDeviceType(props) {
  const {
    setIsSetupDialogTeamApiKeyOpen,
    setIsSetupDialogDeviceTypeOpen,
    setIsSetupDialogDataTypeOpen
  } = props;

  const dialogCancelRef = React.createRef();

  const { deviceType, setDeviceType } = useContext(DeviceContext);
  const { teamApiKey } = useContext(TeamContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    if (teamApiKey.length > 1) {
      setIsLoading(true);
      upsertDeviceType({ teamApiKey })
        .then(res => {
          setDeviceType(res);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          setIsErrored(true);
        });
    }
  }, [teamApiKey, setDeviceType]);

  const getDialogBody = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (!isLoading && isErrored) {
      return (
        <div>
          There was an <strong style={{ color: "red" }}>error</strong> creating
          the device type. Please go back and verify your Team API Key.
        </div>
      );
    }

    if (!isLoading && !isErrored) {
      return (
        <div>
          <strong style={{ color: "green" }}>Success!</strong> Your{" "}
          <a
            href="https://iotery.io/device-types"
            target="_blank"
            rel="noopener noreferrer"
          >
            device type
          </a>{" "}
          was created.
          <pre className={styles.json_box}>
            {JSON.stringify(deviceType, null, 2)}
          </pre>
          <p style={{ fontSize: 12 }}>
            See our&nbsp;
            <a
              href="https://iotery.io/docs/account-manager#tag/Device-Types/paths/~1device-types/post"
              target="_blank"
              rel="noopener noreferrer"
            >
              REST API
            </a>{" "}
            for more info about device types.
          </p>
        </div>
      );
    }
  };

  return (
    <AlertDialogOverlay
      leastDestructiveRef={dialogCancelRef}
      onDismiss={() => {
        setIsSetupDialogDeviceTypeOpen(false);
      }}
    >
      <AlertDialogContent className={styles.dialog_box}>
        <AlertDialogLabel>
          <h2>Creating a Device Type...</h2>
        </AlertDialogLabel>
        <AlertDialogDescription>{getDialogBody()}</AlertDialogDescription>
        <div className={styles.button_row}>
          <button
            ref={dialogCancelRef}
            type="button"
            className={cc([
              styles.button,
              styles.button_cancel,
              {
                [styles.button_disabled]:
                  isLoading || (!isLoading && !isErrored)
              }
            ])}
            disabled={isLoading || (!isLoading && !isErrored)}
            onClick={() => {
              setIsSetupDialogDeviceTypeOpen(false);
              setIsSetupDialogTeamApiKeyOpen(true);
            }}
          >
            Back
          </button>
          <button
            type="button"
            className={cc([
              styles.button,
              { [styles.button_disabled]: isLoading || isErrored }
            ])}
            disabled={isLoading || isErrored}
            onClick={() => {
              setIsSetupDialogDeviceTypeOpen(false);
              setIsSetupDialogDataTypeOpen(true);
            }}
          >
            Next
          </button>
        </div>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
}

function SetupDialogDataType(props) {
  const {
    setIsSetupDialogDataTypeOpen,
    setIsSetupDialogCommandTypesOpen
  } = props;

  const dialogCancelRef = React.createRef();

  const { deviceType } = useContext(DeviceContext);
  const { teamApiKey } = useContext(TeamContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [dataType, setDataType] = useState(null);

  useEffect(() => {
    if (teamApiKey.length > 1 && !!deviceType) {
      setIsLoading(true);
      upsertDataType({ teamApiKey, deviceType })
        .then(res => {
          setDataType(res);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          setIsErrored(true);
        });
    }
  }, [teamApiKey, deviceType]);

  const getDialogBody = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (!isLoading && isErrored) {
      return (
        <div>
          There was an <strong style={{ color: "red" }}>error</strong> creating
          the data type.
        </div>
      );
    }

    if (!isLoading && !isErrored) {
      return (
        <div>
          <strong style={{ color: "green" }}>Success!</strong> Your{" "}
          <a
            href="https://iotery.io/data-types"
            target="_blank"
            rel="noopener noreferrer"
          >
            data type
          </a>{" "}
          was created.
          <pre className={styles.json_box}>
            {JSON.stringify(dataType, null, 2)}
          </pre>
          <p style={{ fontSize: 12 }}>
            See our&nbsp;
            <a
              href="https://iotery.io/docs/account-manager#tag/Data-Types/paths/~1data-types/post"
              target="_blank"
              rel="noopener noreferrer"
            >
              REST API
            </a>{" "}
            for more info about data types.
          </p>
        </div>
      );
    }
  };

  return (
    <AlertDialogOverlay
      leastDestructiveRef={dialogCancelRef}
      onDismiss={() => {
        setIsSetupDialogDataTypeOpen(false);
      }}
    >
      <AlertDialogContent className={styles.dialog_box}>
        <AlertDialogLabel>
          <h2>Creating the Data Type...</h2>
        </AlertDialogLabel>
        <AlertDialogDescription>{getDialogBody()}</AlertDialogDescription>
        <div className={styles.button_row}>
          <button
            type="button"
            ref={dialogCancelRef}
            className={cc([
              styles.button,
              { [styles.button_disabled]: isLoading || isErrored }
            ])}
            disabled={isLoading || isErrored}
            onClick={() => {
              setIsSetupDialogDataTypeOpen(false);
              setIsSetupDialogCommandTypesOpen(true);
            }}
          >
            Next
          </button>
        </div>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
}

function SetupDialogCommandTypes(props) {
  const {
    setIsSetupDialogCommandTypesOpen,
    setIsSetupDialogDeviceOpen
  } = props;

  const dialogCancelRef = React.createRef();

  const { deviceType } = useContext(DeviceContext);
  const { teamApiKey } = useContext(TeamContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [commandTypes, setCommandTypes] = useState(null);

  useEffect(() => {
    if (teamApiKey.length > 1 && !!deviceType) {
      setIsLoading(true);
      upsertCommandTypes({ teamApiKey, deviceType })
        .then(res => {
          setCommandTypes(res);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          setIsErrored(true);
        });
    }
  }, [teamApiKey, deviceType]);

  const getDialogBody = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (!isLoading && isErrored) {
      return (
        <div>
          There was an <strong style={{ color: "red" }}>error</strong> creating
          the command types.
        </div>
      );
    }

    if (!isLoading && !isErrored) {
      return (
        <div>
          <strong style={{ color: "green" }}>Success!</strong> Your{" "}
          <a
            href="https://iotery.io/command-types"
            target="_blank"
            rel="noopener noreferrer"
          >
            command types
          </a>{" "}
          were created.
          <pre className={styles.json_box}>
            {JSON.stringify(commandTypes, null, 2)}
          </pre>
          <p style={{ fontSize: 12 }}>
            See our&nbsp;
            <a
              href="https://iotery.io/docs/account-manager#tag/Command-Types/paths/~1command-types/post"
              target="_blank"
              rel="noopener noreferrer"
            >
              REST API
            </a>{" "}
            for more info about command types.
          </p>
        </div>
      );
    }
  };

  return (
    <AlertDialogOverlay
      leastDestructiveRef={dialogCancelRef}
      onDismiss={() => {
        setIsSetupDialogCommandTypesOpen(false);
      }}
    >
      <AlertDialogContent className={styles.dialog_box}>
        <AlertDialogLabel>
          <h2>Creating Command Types...</h2>
        </AlertDialogLabel>
        <AlertDialogDescription>{getDialogBody()}</AlertDialogDescription>
        <div className={styles.button_row}>
          <button
            type="button"
            ref={dialogCancelRef}
            className={cc([
              styles.button,
              { [styles.button_disabled]: isLoading || isErrored }
            ])}
            disabled={isLoading || isErrored}
            onClick={() => {
              setIsSetupDialogCommandTypesOpen(false);
              setIsSetupDialogDeviceOpen(true);
            }}
          >
            Next
          </button>
        </div>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
}

function SetupDialogDevice(props) {
  const { setIsSetupDialogDeviceOpen, setIsConnectDialogOpen } = props;

  const dialogCancelRef = React.createRef();

  const { deviceType, setTeamUuid, setSecret, setDeviceInfo } = useContext(
    DeviceContext
  );
  const { teamApiKey } = useContext(TeamContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [hasCreated, setHasCreated] = useState(false);
  const [device, setDevice] = useState(null);

  const getDialogBody = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (!isLoading && isErrored) {
      return (
        <div>
          There was an <strong style={{ color: "red" }}>error</strong> creating
          the device.
        </div>
      );
    }

    if (!isLoading && !isErrored && hasCreated) {
      return (
        <div>
          <strong style={{ color: "green" }}>Success!</strong> Your{" "}
          <a
            href="https://iotery.io/devices"
            target="_blank"
            rel="noopener noreferrer"
          >
            device
          </a>{" "}
          was created.
          <pre className={styles.json_box}>
            {JSON.stringify(device, null, 2)}
          </pre>
          <p style={{ fontSize: 12 }}>
            See our&nbsp;
            <a
              href="https://iotery.io/docs/account-manager#tag/Devices/paths/~1devices/post"
              target="_blank"
              rel="noopener noreferrer"
            >
              REST API
            </a>{" "}
            for more info about devices.
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <AlertDialogOverlay
      leastDestructiveRef={dialogCancelRef}
      onDismiss={() => {
        setIsSetupDialogDeviceOpen(false);
      }}
    >
      <AlertDialogContent className={styles.dialog_box}>
        <Formik
          initialValues={{
            secret: "",
            secret2: ""
          }}
          validationSchema={validationSchemaDeviceSecret}
          onSubmit={(values, { setSubmitting, isSubmitting }) => {
            setSubmitting(true);
            if (!hasCreated) {
              setIsLoading(true);
              upsertDevice({
                teamApiKey,
                deviceType: deviceType,
                secret: values.secret
              })
                .then(res => {
                  setDevice(res);
                  setDeviceInfo(res);
                  setTeamUuid(res.teamUuid);
                  setSecret(values.secret);
                  setIsLoading(false);
                  setHasCreated(true);
                  setSubmitting(false);
                })
                .catch(err => {
                  setIsLoading(false);
                  setIsErrored(true);
                  setSubmitting(false);
                });
            } else {
              setIsSetupDialogDeviceOpen(false);
              setIsConnectDialogOpen(true);
            }
          }}
          render={({ values, handleBlur, handleChange, isSubmitting }) => (
            <Form>
              <AlertDialogLabel>
                <h2>Create a device</h2>
              </AlertDialogLabel>
              <AlertDialogDescription>
                <div style={{ textAlign: "justify" }}>
                  Enter your device secret
                </div>
                <div className={styles.input_row}>
                  <label htmlFor="secret">Device Secret *</label>
                  <input
                    required
                    type="password"
                    name="secret"
                    autoComplete="off"
                    placeholder="Enter your secret here"
                    value={values.secret}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="secret"
                    component="div"
                    className={styles.input_error_text_container}
                  />
                </div>
                <div className={styles.input_row}>
                  <label htmlFor="secret2">Confirm Secret *</label>
                  <input
                    required
                    type="password"
                    name="secret2"
                    autoComplete="off"
                    placeholder="Confirm secret"
                    value={values.secret2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="secret2"
                    component="div"
                    className={styles.input_error_text_container}
                  />
                </div>
                <div
                  style={{
                    textAlign: "justify",
                    fontSize: 13,
                    paddingBottom: 20,
                    fontStyle: "italic"
                  }}
                >
                  Tip: This is like a password for your device. Make sure to
                  keep track of it.
                </div>
                {getDialogBody()}
              </AlertDialogDescription>
              <div className={styles.button_row}>
                <button
                  type="submit"
                  className={cc([
                    styles.button,
                    {
                      [styles.button_disabled]:
                        isSubmitting || isLoading || isErrored
                    }
                  ])}
                  disabled={isSubmitting || isLoading || isErrored}
                  ref={dialogCancelRef}
                >
                  {hasCreated ? "Finish" : "Create"}
                </button>
              </div>
            </Form>
          )}
        />
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
}

export default Header;
