import config from "../config/index";
import moment from "moment";

// embedded routes

const loginDevice = async ({ serial, key, secret, teamUuid }) => {
  const body = await _post("/embedded/basic/get-token", {
    serial,
    key,
    secret,
    teamUuid
  });
  return body;
};

const getCommandTypeList = async ({ token }) => {
  const body = await _get(`/embedded/command-types`, token);
  return body;
};

const setCommandAsExecuted = async ({ commandInstanceUuid, token }) => {
  const body = await _patch(
    `/embedded/command-instances/${commandInstanceUuid}/executed`,
    {
      timestamp: moment().unix()
    },
    token
  );
  return body;
};

const postOnOffStateToCloud = async ({
  deviceUuid,
  deviceTypeUuid,
  onOffState,
  token
}) => {
  const body = await _post(
    `/embedded/devices/${deviceUuid}/data`,
    {
      packets: [
        {
          timestamp: moment().unix(),
          deviceUuid,
          deviceTypeUuid,
          data: {
            [config.ioteryTypes.dataTypeEnum]: onOffState
          }
        }
      ]
    },
    token
  );
  return body;
};

const reportExecutedCommand = async ({
  deviceUuid,
  commandTypeUuid,
  token
}) => {
  const body = await _post(
    `/embedded/command-instances/executed`,
    {
      commands: [
        {
          deviceUuid,
          commandTypeUuid,
          timestamp: moment().unix()
        }
      ]
    },
    token
  );
  return body;
};

const getTeamInfo = async ({ token }) => {
  const body = await _get(`/embedded/team`, token);
  return body;
};

// AM routes

const upsertDeviceType = async ({ teamApiKey }) => {
  const token = undefined;

  const { deviceTypeEnum } = config.ioteryTypes;

  const reqBody = {
    name: "Smart Light",
    enum: deviceTypeEnum,
    shortDescription: "A connected light",
    description: "An internet enabled light bulb"
  };

  const deviceTypeList = await _get("/device-types", token, teamApiKey).then(
    r => r.results
  );
  const deviceType = deviceTypeList.find(x => x.enum === deviceTypeEnum);
  let resBody;
  if (deviceType) {
    resBody = await _patch(
      `/device-types/${deviceType.uuid}`,
      reqBody,
      token,
      teamApiKey
    );
  } else {
    resBody = await _post(`/device-types`, reqBody, token, teamApiKey);
  }

  return resBody;
};

const upsertDataType = async ({ teamApiKey, deviceType }) => {
  const token = undefined;

  const { dataTypeEnum } = config.ioteryTypes;

  const reqBody = {
    name: "On/Off State",
    enum: dataTypeEnum,
    units: "dimensionless",
    description: `The binary on/off state of a smart light. Will take the value of 0 for "off" and 1 for "on".`,
    minValue: 0,
    maxValue: 1,
    isNumber: true,
    deviceTypeUuid: deviceType.uuid
  };

  const dataTypeList = await _get("/data-types", token, teamApiKey).then(
    r => r.results
  );
  const dataType = dataTypeList.find(x => x.enum === dataTypeEnum);
  let resBody;
  if (dataType) {
    resBody = await _patch(
      `/data-types/${dataType.uuid}`,
      reqBody,
      token,
      teamApiKey
    );
  } else {
    resBody = await _post(`/data-types`, reqBody, token, teamApiKey);
  }

  resBody.deviceType && delete resBody.deviceType;

  return resBody;
};

const upsertCommandTypes = async ({ teamApiKey, deviceType }) => {
  const token = undefined;

  const commandTypeEnumTurnOn = config.ioteryTypes.commands.turnOn;
  const commandTypeEnumTurnOff = config.ioteryTypes.commands.turnOff;

  const priorityTypeList = await _get(
    "/priority-types",
    token,
    teamApiKey
  ).then(r => r.results);

  const priorityType = priorityTypeList.find(x => x.enum === "HIGH");

  const reqBodyOn = {
    name: "Turn Light On",
    enum: commandTypeEnumTurnOn,
    deviceTypeUuid: deviceType.uuid,
    priorityTypeUuid: priorityType.uuid
  };

  const reqBodyOff = {
    name: "Turn Light Off",
    enum: commandTypeEnumTurnOff,
    deviceTypeUuid: deviceType.uuid,
    priorityTypeUuid: priorityType.uuid
  };

  const commandTypeList = await _get("/command-types", token, teamApiKey).then(
    r => r.results
  );

  const commandTypeTurnOn = commandTypeList.find(
    x => x.enum === commandTypeEnumTurnOn
  );

  const commandTypeTurnOff = commandTypeList.find(
    x => x.enum === commandTypeEnumTurnOff
  );

  let resBodyOn;
  if (commandTypeTurnOn) {
    resBodyOn = await _patch(
      `/command-types/${commandTypeTurnOn.uuid}`,
      reqBodyOn,
      token,
      teamApiKey
    );
  } else {
    resBodyOn = await _post(`/command-types`, reqBodyOn, token, teamApiKey);
  }

  let resBodyOff;
  if (commandTypeTurnOff) {
    resBodyOff = await _patch(
      `/command-types/${commandTypeTurnOff.uuid}`,
      reqBodyOff,
      token,
      teamApiKey
    );
  } else {
    resBodyOff = await _post(`/command-types`, reqBodyOff, token, teamApiKey);
  }

  resBodyOn.deviceType && delete resBodyOn.deviceType;
  resBodyOff.deviceType && delete resBodyOff.deviceType;

  return [resBodyOn, resBodyOff];
};

const upsertDevice = async ({ teamApiKey, deviceType, secret }) => {
  const token = undefined;

  const serial = "5077D843";

  const reqBody = {
    serial,
    key: "1496BD8860D2",
    secret,
    name: "My Iotery Smart Light",
    deviceTypeUuid: deviceType.uuid,
    securityType: "BASIC"
  };

  const deviceList = await _get(
    `/devices?serial=${serial}`,
    token,
    teamApiKey
  ).then(r => r.results);

  const device = deviceList.find(x => x.serial === serial);

  let resBody;
  if (device) {
    resBody = await _patch(
      `/devices/${device.uuid}`,
      reqBody,
      token,
      teamApiKey
    );
  } else {
    resBody = await _post(
      `/devices`,
      { ...reqBody, isProxy: true },
      token,
      teamApiKey
    );
  }

  resBody.deviceType && delete resBody.deviceType;
  resBody.qrCode && delete resBody.qrCode;
  resBody.privateKey && delete resBody.privateKey;
  resBody.avatarUrl && delete resBody.avatarUrl;

  return resBody;
};

async function _post(
  route,
  reqBody,
  token = undefined,
  teamApiKey = undefined
) {
  const url = `${config.baseApiUrl}${route}`;

  let headers = {
    "Content-Type": "application/json"
  };
  if (token) {
    headers = { ...headers, "x-access-token": token };
  }
  if (teamApiKey) {
    headers = { ...headers, "x-api-key": teamApiKey };
  }

  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(reqBody),
    headers
  });
  let resBody = null;
  if (res.status >= 400) {
    throw res;
  } else {
    resBody = await res.json();
  }
  return resBody;
}

async function _get(route, token = undefined, teamApiKey = undefined) {
  const url = `${config.baseApiUrl}${route}`;

  let headers = {
    "Content-Type": "application/json"
  };
  if (token) {
    headers = { ...headers, "x-access-token": token };
  }
  if (teamApiKey) {
    headers = { ...headers, "x-api-key": teamApiKey };
  }

  const res = await fetch(url, {
    method: "GET",
    headers
  });
  let resBody = null;
  if (res.status >= 400) {
    throw res;
  } else {
    resBody = await res.json();
  }
  return resBody;
}

async function _patch(
  route,
  reqBody,
  token = undefined,
  teamApiKey = undefined
) {
  const url = `${config.baseApiUrl}${route}`;

  let headers = {
    "Content-Type": "application/json"
  };
  if (token) {
    headers = { ...headers, "x-access-token": token };
  }
  if (teamApiKey) {
    headers = { ...headers, "x-api-key": teamApiKey };
  }

  const res = await fetch(url, {
    method: "PATCH",
    body: JSON.stringify(reqBody),
    headers
  });
  let resBody = null;
  if (res.status >= 400) {
    throw res;
  } else {
    resBody = await res.json();
  }
  return resBody;
}

export {
  loginDevice,
  getCommandTypeList,
  setCommandAsExecuted,
  postOnOffStateToCloud,
  reportExecutedCommand,
  upsertDeviceType,
  upsertDataType,
  upsertCommandTypes,
  upsertDevice,
  getTeamInfo
};
