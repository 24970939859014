import * as React from "react";
import styles from "./CircularProgress.module.css";

export default function CircularProgress() {
  return (
    <div className={styles.root}>
      <svg viewBox="0 0 32 32" className={styles.svg}>
        <circle
          stroke="black"
          opacity={0.2}
          cx="16px"
          cy="16px"
          fill="none"
          r="14px"
          strokeWidth="2px"
        />
        <circle
          stroke="black"
          strokeDasharray={80}
          strokeDashoffset={60}
          cx="16px"
          cy="16px"
          fill="none"
          r="14px"
          strokeWidth="2px"
        />
      </svg>
    </div>
  );
}
