import React, { useContext, useEffect } from "react";

import { DeviceContext } from "../../contexts/DeviceContext";
import { TeamContext } from "../../contexts/TeamContext";

import styles from "./DevicePage.module.css";

import { Icon } from "@iconify/react";
import lightbulbIcon from "@iconify/icons-mdi/lightbulb";

import cc from "classcat";

import {
  getCommandTypeList,
  reportExecutedCommand,
  postOnOffStateToCloud
} from "../../lib/api";

import config from "../../config/index";

const DevicePage = props => {
  const {
    isConnected,
    isOn,
    setIsOn,
    deviceInfo,
    token,
    setCommandTypeList,
    commandTypeList
  } = useContext(DeviceContext);

  const { teamUuid, teamName } = useContext(TeamContext);

  useEffect(() => {
    if (isConnected && !!token) {
      getCommandTypeList({ token }).then(commandTypeList => {
        setCommandTypeList(commandTypeList);
      });
    }
  }, [isConnected, token, setCommandTypeList]);

  const toggleLight = () => {
    let commandType;
    if (isConnected) {
      if (isOn) {
        // turn off
        setIsOn(false);
        commandType = commandTypeList.find(
          x => x.enum === config.ioteryTypes.commands.turnOff
        );
        reportExecutedCommand({
          deviceUuid: deviceInfo.uuid,
          commandTypeUuid: commandType.uuid,
          token
        });
        postOnOffStateToCloud({
          deviceUuid: deviceInfo.uuid,
          deviceTypeUuid: deviceInfo.deviceTypeUuid,
          onOffState: 0,
          token
        });
      } else {
        // turn on
        setIsOn(true);
        commandType = commandTypeList.find(
          x => x.enum === config.ioteryTypes.commands.turnOn
        );
        reportExecutedCommand({
          deviceUuid: deviceInfo.uuid,
          commandTypeUuid: commandType.uuid,
          token
        });
        postOnOffStateToCloud({
          deviceUuid: deviceInfo.uuid,
          deviceTypeUuid: deviceInfo.deviceTypeUuid,
          onOffState: 1,
          token
        });
      }
    }
  };

  const getBasicInfoContainer = () => {
    let connection = {
      style: styles.red_circle,
      string: "offline",
      visibility: "collapse"
    };
    if (isConnected) {
      connection = {
        style: styles.green_circle,
        string: "online",
        visibility: "visible"
      };
    }

    const teamUuidStart = isConnected ? teamUuid.split("-")[0] : "xxxxxxxx";

    return (
      <div className={styles.basic_info_container}>
        <div className={styles.basic_info_header} style={{ marginTop: 0 }}>
          Status
        </div>
        <div className={styles.basic_info_data}>
          <div className={styles.basic_info_row_label}>Connection</div>
          <div className={styles.basic_info_row_value}>
            <div
              className={connection.style}
              style={{
                animationDelay: "0s"
              }}
            />
            <div
              className={connection.style}
              style={{
                animationDelay: "2s",
                position: "relative",
                right: 15,
                visibility: connection.visibility
              }}
            />
            <div
              className={connection.style}
              style={{
                animationDelay: "4s",
                position: "relative",
                right: 30,
                visibility: connection.visibility
              }}
            />
            <div
              className={connection.style}
              style={{
                animationDelay: "6s",
                position: "relative",
                right: 45,
                visibility: connection.visibility
              }}
            />
            <span
              style={{
                position: "relative",
                right: 43
              }}
            >
              {connection.string}
            </span>
          </div>
        </div>
        <div className={styles.basic_info_data}>
          <div className={styles.basic_info_row_label}>Light State</div>
          <div className={styles.basic_info_row_value}>
            {isOn ? "ON" : "OFF"}
          </div>
        </div>

        <div className={styles.basic_info_header}>Device Information</div>
        <div className={styles.basic_info_data}>
          <div className={styles.basic_info_row_label}>UUID</div>
          <div className={styles.basic_info_row_value}>
            {isConnected
              ? deviceInfo.uuid
              : "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
          </div>
        </div>
        <div className={styles.basic_info_data}>
          <div className={styles.basic_info_row_label}>Name</div>
          <div className={styles.basic_info_row_value}>
            {isConnected ? deviceInfo.name : "..."}
          </div>
        </div>
        <div className={styles.basic_info_data}>
          <div className={styles.basic_info_row_label}>Serial</div>
          <div className={styles.basic_info_row_value}>
            {isConnected ? deviceInfo.serial : "..."}
          </div>
        </div>
        <div className={styles.basic_info_data}>
          <div className={styles.basic_info_row_label}>Key</div>
          <div className={styles.basic_info_row_value}>
            {isConnected ? deviceInfo.key : "..."}
          </div>
        </div>

        <div className={styles.basic_info_header}>Team Information</div>
        <div className={styles.basic_info_data}>
          <div className={styles.basic_info_row_label}>UUID</div>
          <div className={styles.basic_info_row_value}>
            {`${teamUuidStart}-xxxx-xxxx-xxxx-xxxxxxxxxxxx`}
          </div>
        </div>
        <div className={styles.basic_info_data}>
          <div className={styles.basic_info_row_label}>Name</div>
          <div className={styles.basic_info_row_value}>
            {isConnected ? teamName : "..."}
          </div>
        </div>
      </div>
    );
  };

  const getGraphicContainer = () => {
    let iconClasses = [styles.bulb, styles.bulb_off];
    if (isOn) iconClasses = [styles.bulb, styles.bulb_on];

    if (!isConnected) iconClasses.push(styles.bulb_offline);

    const ioteryDashboardLink = deviceInfo.uuid
      ? `https://iotery.io/devices/${deviceInfo.uuid}/commands`
      : "https://iotery.io";

    return (
      <div className={styles.graphic_container}>
        <Icon
          icon={lightbulbIcon}
          height={200}
          className={cc(iconClasses)}
          onClick={() => toggleLight()}
        />
        <p>
          {" "}
          <a
            href={ioteryDashboardLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Iotery Dashboard
          </a>
        </p>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {getBasicInfoContainer()}
      {getGraphicContainer()}
      <div style={{ flexGrow: 10 }}></div>
    </div>
  );
};

export default DevicePage;
