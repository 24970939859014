const registry = {
  _mqttInstance: null
};

registry.registerMqttInstance = mqttInstance => {
  registry._mqttInstance = mqttInstance;
};

registry.getMqttInstance = () => {
  return registry._mqttInstance;
};

export default registry;
