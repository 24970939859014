import React, { createContext, useState } from "react";

export const TeamContext = createContext();

const TeamContextProvider = props => {
  const [teamUuid, setTeamUuid] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamApiKey, setTeamApiKey] = useState("");

  return (
    <TeamContext.Provider
      value={{
        teamUuid,
        setTeamUuid,
        teamApiKey,
        setTeamApiKey,
        teamName,
        setTeamName
      }}
    >
      {props.children}
    </TeamContext.Provider>
  );
};

export default TeamContextProvider;
