import React from "react";
import "@reach/dialog/styles.css";

// Context
import DeviceContextProvider from "./contexts/DeviceContext";
import TeamContextProvider from "./contexts/TeamContext";

// Components
import Header from "./components/header/Header";
import DevicePage from "./components/device-page/DevicePage";

// Initialize singleton registry (prevents require cycles)
import singletons from "./lib/singletons";
import mqttClient from "./lib/mqtt";
singletons.registerMqttInstance(mqttClient);

function App() {
  return (
    <div className="App">
      <TeamContextProvider>
        <DeviceContextProvider>
          <div style={{ flex: "1 0 auto" }}>
            <Header />
            <div
              style={{
                marginTop: 20,
                marginRight: 40,
                marginLeft: 40,
                color: "#222",
                maxWidth: 600,
                display: "flex",
                textAlign: "justify"
              }}
            >
              <p>
                This emulator communicates with the Iotery Embedded API and MQTT
                Broker. It can be used to develop your value added (VA) cloud
                system in parallel with hardware. For more information, read
                about the &nbsp;
                <a href="https://iotery.io/docs/concepts">
                  Basic Iotery Concepts
                </a>
                &nbsp; and see our <a href="https://iotery.io/docs">API docs</a>
                . Click "connect" above and enter your credentials to activate
                the emulator.
              </p>
            </div>
            <DevicePage />
          </div>
          <footer style={{ lineHeight: 1, fontSize: 11 }}>
            <p>Copyright &copy; 2020 Iotery</p>
          </footer>
        </DeviceContextProvider>
      </TeamContextProvider>
    </div>
  );
}

export default App;
