import MQTT from "async-mqtt";

import config from "../config/index";
import * as moment from "moment";

export class MqttClient {
  constructor() {
    this.client = null;
    this.subscribedTopics = [];
    this.username = null;
    this.password = null;
    this.listeners = [];
  }

  addCredentials(username, password, teamUuid) {
    this.username = username;
    this.password = password;
    this.teamUuid = teamUuid;
  }

  init() {
    if (!this.client) {
      const client = MQTT.connect(config.baseMqttUrl, {
        username: this.username,
        password: this.password,
        clientId: `${this.teamUuid}:${moment().valueOf()}:${this.username}`
      });
      client.on("connect", () => console.log("MQTT Connected")); // eslint-disable-line
      client.on("close", () => console.log("MQTT Disconnected")); // eslint-disable-line
      this.client = client;
    }
  }

  addOnMessageListener(listener) {
    if (this.client && !this.listeners.includes(l => l === listener)) {
      this.client.on("message", listener);
      this.listeners.push(listener);
    }
  }

  subscribe(topic) {
    if (this.client) {
      this.client.subscribe(topic);
      this.subscribedTopics.push(topic);
    }
  }

  unsubscribe(topic) {
    if (this.client && topic) {
      this.client.unsubscribe(topic);
    }
  }

  removeOnMessageListener(type, listener) {
    if (this.client) {
      this.client.removeListener("message", listener);
    }
  }

  removeAllOnMessageListeners() {
    this.listeners.forEach(l => this.removeOnMessageListener(null, l));
    this.listeners = [];
  }

  unsubscribeFromAll() {
    if (this.client) {
      const topicsToUnsubscribe = this.subscribedTopics;
      this.client.unsubscribe(topicsToUnsubscribe);
      this.subscribedTopics = [];
    }
  }

  disconnect() {
    if (this.client) {
      this.client.end();
      this.client = null;
    }
  }
}

const instance = new MqttClient();
export default instance;
