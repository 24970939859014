import React, { createContext, useState } from "react";

export const DeviceContext = createContext();

const DeviceContextProvider = props => {
  const [isConnected, setIsConnected] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [token, setToken] = useState("");

  const [commandTypeList, setCommandTypeList] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState({
    serial: "",
    key: ""
  });
  const [deviceType, setDeviceType] = useState(null);
  const [teamUuid, setTeamUuid] = useState("");
  const [secret, setSecret] = useState("");

  return (
    <DeviceContext.Provider
      value={{
        isConnected,
        setIsConnected,
        isOn,
        setIsOn,
        token,
        setToken,
        deviceInfo,
        setDeviceInfo,
        commandTypeList,
        setCommandTypeList,
        deviceType,
        setDeviceType,
        teamUuid,
        setTeamUuid,
        secret,
        setSecret
      }}
    >
      {props.children}
    </DeviceContext.Provider>
  );
};

export default DeviceContextProvider;
