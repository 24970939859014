const config = {
  baseApiUrl: process.env.REACT_APP_BASE_API_URL || "https://api.iotery.io/v1",
  baseMqttUrl: process.env.REACT_APP_BASE_MQTT_URL || "wss://ws.iotery.io",
  ioteryTypes: {
    deviceTypeEnum: "IOTERY_SMART_LIGHT",
    dataTypeEnum: "IOTERY_ON_OFF_STATE",
    commands: {
      turnOn: "IOTERY_TURN_LIGHT_ON",
      turnOff: "IOTERY_TURN_LIGHT_OFF"
    }
  }
};

export default config;
